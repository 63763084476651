import { Action, handleActions } from "redux-actions";
import {
  GET_WIZARD_DATA_SUCCESS,
  SEND_ORDER_SUCCESS,
  SET_WIZARD_DATA_SUCCESS
} from "../constants/wizard.constants";

const getWizardDataSuccess = (state: any, { payload }: Action<any>) => {
  return payload;
};

const setWizardDataSuccess = (state: any, { payload }: Action<any>) => {
  return payload;
};

const sendOrderSuccess = (state: any, { payload }: Action<any>) => {
  return {};
};

const loadState = () => ({});

export const wizardData = handleActions(
  {
    [GET_WIZARD_DATA_SUCCESS]: getWizardDataSuccess,
    [SET_WIZARD_DATA_SUCCESS]: setWizardDataSuccess,
    [SEND_ORDER_SUCCESS]: sendOrderSuccess
  },
  loadState()
);
