import { WizardStep } from "../enums/wizard-step.enum";
import { IWizardConfig } from "../interfaces/wizard-config.interface";
import { WizardOperation } from "../enums/wizard-operation.enum";
import { WizardSelector } from "../enums/wizard-selector.enum";

export const wizardConfig: IWizardConfig = {
  firstStep: WizardStep.Selector,
  steps: {
    [WizardStep.Selector]: {
      route: "/wizard-selector",
      nextStep: WizardStep.Operation
    },
    [WizardStep.Operation]: {
      route: "/wizard-operations",
      nextStep: (currentStepData: any, wizardData: any) => {
        if (currentStepData === WizardOperation.Wireline) {
          if (wizardData[WizardStep.Selector] === WizardSelector.Guns) {
            return WizardStep.WellProfile;
          } else if (
            wizardData[WizardStep.Selector] === WizardSelector.Charges
          ) {
            return WizardStep.ChargeProfile;
          }
        } else if (currentStepData === WizardOperation.Tcp) {
          return WizardStep.TcpOperation;
        }
        throw Error(`incorrect operation ${currentStepData}`);
      }
    },
    [WizardStep.WellProfile]: {
      route: "/wizard-well-profile",
      nextStep: WizardStep.WellProfile2
    },
    [WizardStep.WellProfile2]: {
      route: "/wizard-well-profile-2",
      nextStep: (currentStepData: any, wizardData: any) => {
        const operation = wizardData[WizardStep.Selector];

        if (operation === WizardSelector.Guns) {
          return WizardStep.GunProfile;
        } else if (operation === WizardSelector.Charges) {
          return WizardStep.ChargeProfile;
        }
        return wizardConfig.firstStep;
      }
    },
    [WizardStep.TcpOperation]: {
      route: "/wizard-tcp"
    },
    [WizardStep.GunProfile]: {
      route: "/wizard-gun-profile",
      nextStep: WizardStep.GunType
    },
    [WizardStep.GunType]: {
      route: "/wizard-gun-type",
      nextStep: WizardStep.Guns
    },
    [WizardStep.ChargeProfile]: {
      route: "/wizard-charge-profile",
      nextStep: WizardStep.Charges
    },
    [WizardStep.Charges]: {
      route: "/wizard-charges",
      nextStep: (currentStepData: any, wizardData: any) => {
        if (wizardData[WizardStep.Selector] === WizardSelector.Guns) {
          return WizardStep.ToolsAndHardware;
        } else if (wizardData[WizardStep.Selector] === WizardSelector.Charges) {
          return WizardStep.BillOfMaterials;
        }
      }
    },
    [WizardStep.Guns]: {
      route: "/wizard-guns",
      nextStep: (currentStepData: any, wizardData: any) => {
        const operation = wizardData[WizardStep.Selector];

        if (operation === WizardSelector.Guns) {
          return WizardStep.Charges;
        } else if (operation === WizardSelector.Charges) {
          return WizardStep.RequestGem;
        }
        return wizardConfig.firstStep;
      }
    },
    [WizardStep.RequestGem]: {
      route: "/wizard-request-gem",
      nextStep: WizardStep.ToolsAndHardware
    },
    [WizardStep.DirectToolsAndHardware]: {
      route: "/direct-tools-and-hardware",
      nextStep: WizardStep.BillOfMaterials
    },
    [WizardStep.ToolsAndHardware]: {
      route: "/wizard-tools-and-hardware",
      nextStep: WizardStep.BillOfMaterials
    },
    [WizardStep.BillOfMaterials]: {
      route: "/wizard-bill-of-materials"
    }
  }
};
