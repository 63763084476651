import { Action, handleActions } from "redux-actions";
import {
  GET_WIZARD_HISTORY_SUCCESS,
  PUSH_TO_WIZARD_HISTORY_SUCCESS
} from "../constants/wizard.constants";

const getWizardHistorySuccess = (state: any, { payload }: Action<any>) => {
  return payload;
};

const pushToWizardHistorySuccess = (
  state: string[],
  { payload }: Action<any>
) => {
  const stepIndex: number = state.indexOf(payload);
  if (stepIndex === -1) {
    return state.concat(payload);
  } else {
    return state.slice(0, stepIndex + 1);
  }
};

const loadState = () => [];

export const wizardHistory = handleActions(
  {
    [GET_WIZARD_HISTORY_SUCCESS]: getWizardHistorySuccess,
    [PUSH_TO_WIZARD_HISTORY_SUCCESS]: pushToWizardHistorySuccess
  },
  loadState()
);
