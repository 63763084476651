import { createAction } from "redux-actions";
import {
  GET_ATTR_VALUES_ERROR,
  GET_ATTR_VALUES_REQUEST,
  GET_ATTR_VALUES_SUCCESS
} from "../constants/attr-values.constants";

// get attr values (begin)
export const getAttrValuesRequest = (data: any) =>
  createAction(GET_ATTR_VALUES_REQUEST)(data);

export const getAttrValuesSuccess = (data: any) =>
  createAction(GET_ATTR_VALUES_SUCCESS)(data);

export const getAttrValuesError = (data: any) =>
  createAction(GET_ATTR_VALUES_ERROR)(data);
// get attr values (end)
