import { combineReducers } from "redux";
import { wizardData } from "./wizard-data.reducer";
import { currentUser } from "./current-user.reducer";
import { navMenus } from "./nav-menus.reducer";
import { connectPages } from "./connect-pages.reducer";
import { ordersHistory } from "./orders-history.reducer";
import { wizardHistory } from "./wizard-history.reducer";
import { connectSelectOptions } from "./connect-select-options.reducer";
import { forms } from "./forms.reducer";

export const rootReducer = combineReducers<any>({
  wizardData,
  currentUser,
  navMenus,
  connectPages,
  ordersHistory,
  wizardHistory,
  connectSelectOptions,
  forms
});
