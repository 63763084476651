export enum WizardStep {
  Registration = "registration",
  Selector = "selector",
  Operation = "operation",
  WellProfile = "wellProfile",
  WellProfile2 = "wellProfile2",
  TcpOperation = "tcpOperation",
  GunProfile = "gunProfile",
  GunType = "gunType",
  ChargeProfile = "chargeProfile",
  Guns = "guns",
  Charges = "charges",
  RequestGem = "requestGem",
  ToolsAndHardware = "toolsAndHardware",
  DirectToolsAndHardware = "directToolsAndHardware",
  BillOfMaterials = "billOfMaterials"
}
