import { createAction } from "redux-actions";
import {
  GET_ALL_CONTENT_ERROR,
  GET_ALL_CONTENT_REQUEST,
  GET_ALL_CONTENT_SUCCESS
} from "../constants/all-content.constants";

// get all content (begin)
export const getAllContentRequest = () =>
  createAction(GET_ALL_CONTENT_REQUEST)();

export const getAllContentSuccess = (content: any) =>
  createAction(GET_ALL_CONTENT_SUCCESS)(content);

export const getAllContentError = (err: any) =>
  createAction(GET_ALL_CONTENT_ERROR)(err);
// get all content (end)
