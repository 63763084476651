import { Component } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { ConnectService } from "./services/connect.service";
import { AuthService } from "./services/auth.service";
import { environment } from "../environments/environment";
import Debug from "debug";
import { APP_DEBUG_SCOPE } from "./constants/app-debug-scope.constants";
import { select } from "@angular-redux/store";
import { combineLatest, Observable } from "rxjs";
import { Event, NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { PageMetaService } from "@bubbleup/ngx-common";

import $ from "jquery";
import 'bootstrap';

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"]
})
export class AppComponent {
  @select() connectPages$: Observable<any>;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private connectService: ConnectService,
    private authService: AuthService,
    private router: Router,
    private pageMetaService: PageMetaService
  ) {
    this.initializeApp();
  }

  initializeApp(): void {
    if (!environment.production) {
      Debug.enable(`${APP_DEBUG_SCOPE}:*`);
    }

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.connectService.getAllContent();

    this.injectPageMetaOnRouteChange();
  }

  private injectPageMetaOnRouteChange() {
    let prevUrl: string | null = null;

    combineLatest([
      this.connectPages$,
      this.router.events.pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects.split("#")[0]),
        filter((currentUrl: string) => {
          if (currentUrl !== prevUrl) {
            prevUrl = currentUrl;
            return true;
          }
          return false;
        })
      )
    ]).subscribe(([connectPages, currentUrl]) => {
      if (connectPages[currentUrl]) {
        this.pageMetaService.injectPageMeta(connectPages[currentUrl]);
      } else {
        this.pageMetaService.injectDefaultPageMeta();
      }
    });
  }
}
