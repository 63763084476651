import { createAction } from "redux-actions";
import {
  DELETE_ORDER_ERROR,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  GET_ORDERS_HISTORY_ERROR,
  GET_ORDERS_HISTORY_REQUEST,
  GET_ORDERS_HISTORY_SUCCESS,
  GET_WIZARD_DATA_SUCCESS,
  GET_WIZARD_HISTORY_SUCCESS,
  PUSH_TO_WIZARD_HISTORY_SUCCESS,
  REPEAT_ORDER_ERROR,
  REPEAT_ORDER_REQUEST,
  REPEAT_ORDER_SUCCESS,
  SEND_ORDER_SUCCESS,
  SET_WIZARD_DATA_SUCCESS
} from "../constants/wizard.constants";
import { WizardStep } from "../../enums/wizard-step.enum";

// get wizard data (begin)
export const getWizardDataSuccess = (data: any) =>
  createAction(GET_WIZARD_DATA_SUCCESS)(data);
// get wizard data (end)

// get wizard history (begin)
export const getWizardHistorySuccess = (data: any) =>
  createAction(GET_WIZARD_HISTORY_SUCCESS)(data);
// get wizard history (begin)

// set wizard data (begin)
export const setWizardDataSuccess = (data: any) =>
  createAction(SET_WIZARD_DATA_SUCCESS)(data);
// set wizard data (end)

// send order (begin)
export const sendOrderSuccess = (orderData: any) =>
  createAction(SEND_ORDER_SUCCESS)(orderData);
// send order (end)

// send order (begin)
export const repeatOrderRequest = () => createAction(REPEAT_ORDER_REQUEST)();
export const repeatOrderSuccess = (orderData: any) =>
  createAction(REPEAT_ORDER_SUCCESS)(orderData);
export const repeatOrderError = (err: any) =>
  createAction(REPEAT_ORDER_ERROR)(err);
// send order (end)

// get orders history (begin)
export const getOrdersHistoryRequest = () =>
  createAction(GET_ORDERS_HISTORY_REQUEST)();

export const getOrdersHistorySuccess = (ordersHistory: any) =>
  createAction(GET_ORDERS_HISTORY_SUCCESS)(ordersHistory);

export const getOrdersHistoryError = (err: any) =>
  createAction(GET_ORDERS_HISTORY_ERROR)(err);
// get orders history (end)

// send order (begin)
export const deleteOrderRequest = (id: number) =>
  createAction(DELETE_ORDER_REQUEST)(id);
export const deleteOrderSuccess = (id: number) =>
  createAction(DELETE_ORDER_SUCCESS)(id);
export const deleteOrderError = (err: any) =>
  createAction(DELETE_ORDER_ERROR)(err);
// send order (end)

// push to wizard history (begin)
export const pushToWizardHistorySuccess = (step: WizardStep) =>
  createAction(PUSH_TO_WIZARD_HISTORY_SUCCESS)(step);
// push to wizard history (end)
