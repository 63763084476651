import { Injectable } from "@angular/core";
import { IAuthService } from "../interfaces/auth-service.interface";
import { NgRedux } from "@angular-redux/store";
import {
  initCurrentUserSuccess,
  logoutSuccess,
  signInError,
  signInRequest,
  signInSuccess,
  signUpRequest,
  signUpSuccess
} from "../redux/actions/auth.actions";
import { CredentialsService } from "./credentials.service";
import { UserService } from "./user.service";
import { ConnectService } from "./connect.service";
import { HttpMethod } from "../enums/http-method.enum";

@Injectable({
  providedIn: "root"
})
export class AuthService implements IAuthService {
  constructor(
    private redux: NgRedux<any>,
    private credentialsService: CredentialsService,
    private userService: UserService,
    private connectService: ConnectService
  ) {}

  initCurrentUser(): Promise<void> {
    const credentials = this.credentialsService.getCachedCredentials();

    if (!credentials) {
      this.redux.dispatch(initCurrentUserSuccess(null));
      return Promise.resolve();
    }

    return this.userService
      .getCurrentUser()
      .then(user => {
        this.redux.dispatch(initCurrentUserSuccess(user));
      })
      .catch(err => {
        this.redux.dispatch(initCurrentUserSuccess(null));
      });
  }

  isAuthenticated(): boolean {
    const state: any = this.redux.getState();
    return state.currentUser ? true : false;
  }

  signIn(signInCredentials: any): Promise<any> {
    this.redux.dispatch(signInRequest(signInCredentials));

    return this.connectService
      .unauthCallApi<any>("login", HttpMethod.POST, signInCredentials)
      .then(credentials => this.credentialsService.setCredentials(credentials))
      .then(() => this.userService.getCurrentUser())
      .then(user => {
        this.redux.dispatch(signInSuccess(user));

        return user;
      })
      .catch(err => {
        this.redux.dispatch(signInError(err));

        throw err;
      });
  }

  signUp(signUpCredentials: any): Promise<any> {
    this.redux.dispatch(signUpRequest(signUpCredentials));

    return this.connectService
      .unauthCallApi<any>("register-user", HttpMethod.POST, signUpCredentials)
      .then(credentials => this.credentialsService.setCredentials(credentials))
      .then(() => this.userService.getCurrentUser())
      .then(user => {
        this.redux.dispatch(signUpSuccess(user));

        return user;
      })
      .catch(err => {
        this.redux.dispatch(signInError(err));

        throw err;
      });
  }

  logout(): Promise<any> {
    return this.credentialsService.removeCredentials().then(() => {
      this.redux.dispatch(logoutSuccess());
    });
  }

  forgotPassword(username: string): Promise<any> {
    return this.connectService
      .unauthCallApi<any>("forgot-password", HttpMethod.POST, {
        username
      })
      .then(res => {
        return res.token;
      })
      .catch(err => {
        throw err;
      });
  }

  resetPassword(resetPasswordData: any): Promise<any> {
    return this.connectService
      .unauthCallApi<any>("reset-password", HttpMethod.POST, resetPasswordData)
      .catch(err => {
        throw err;
      });
  }
}
