import { createAction } from "redux-actions";
import {
  INIT_CURRENT_USER_SUCCESS,
  LOGOUT_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS
} from "../constants/auth.constants";

// init current user (begin)
export const initCurrentUserSuccess = (user: any) =>
  createAction(INIT_CURRENT_USER_SUCCESS)(user);
// init current user (end)

// sign in (begin)
export const signInRequest = (credentials: any) =>
  createAction(SIGN_IN_REQUEST)(credentials);

export const signInSuccess = (user: any) => createAction(SIGN_IN_SUCCESS)(user);

export const signInError = (error: any) => createAction(SIGN_IN_ERROR)(error);
// sign in (end)

// sign up (begin)
export const signUpRequest = (credentials: any) =>
  createAction(SIGN_UP_REQUEST)(credentials);

export const signUpSuccess = (user: any) => createAction(SIGN_UP_SUCCESS)(user);

export const signUpError = (error: any) => createAction(SIGN_UP_ERROR)(error);
// sign up (end)

// logout (begin)
export const logoutSuccess = () => createAction(LOGOUT_SUCCESS)();
// logout (end)
