import { Injectable } from "@angular/core";
import { ConnectService } from "./connect.service";
import { HttpMethod } from "../enums/http-method.enum";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private connectService: ConnectService) {}

  getCurrentUser(): Promise<any> {
    return this.connectService.callApi("me", HttpMethod.GET);
  }
}
