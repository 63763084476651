import { Action, handleActions } from "redux-actions";
import {
  INIT_CURRENT_USER_SUCCESS,
  LOGOUT_SUCCESS,
  SIGN_IN_SUCCESS,
  SIGN_UP_SUCCESS
} from "../constants/auth.constants";

const initCurrentUserSuccess = (state: any, { payload }: Action<any>) => {
  return payload;
};

const signInSuccess = initCurrentUserSuccess;
const signUpSuccess = initCurrentUserSuccess;

const logoutSuccess = (state: any, { payload }: Action<any>) => {
  return null;
};

const loadState = () => null;

export const currentUser = handleActions(
  {
    [INIT_CURRENT_USER_SUCCESS]: initCurrentUserSuccess,
    [SIGN_IN_SUCCESS]: signInSuccess,
    [SIGN_UP_SUCCESS]: signUpSuccess,
    [LOGOUT_SUCCESS]: logoutSuccess
  },
  loadState()
);
