import { environment } from "../../environments/environment";
import { AuthService } from "../services/auth.service";
import { StoreConfiguratorService } from "@bubbleup/ngx-redux";
import { WizardService } from "../services/wizard.service";
import { CredentialsService } from "../services/credentials.service";

export const appInitializer = (
  storeConfigurator: StoreConfiguratorService,
  authService: AuthService,
  wizardService: WizardService,
  credentialsService: CredentialsService
) => () => {
  storeConfigurator.configureStore(environment.production);
  return Promise.all([
    wizardService.getWizardData(),
    wizardService.getWizardHistory(),
    credentialsService
      .getCredentials()
      .then(() => authService.initCurrentUser())
  ]);
};
