import { combineReducers } from "redux";
import { casingSizeOptions } from "./connect-select-options/casing-size-options.reducer";
import { casingWeightOptions } from "./connect-select-options/casing-weight-options.reducer";
import { maxTemperatureAtOneHourOptions } from "./connect-select-options/max-temperature-at-one-hour-options.reducer";
import { gunSpecificationOptions } from "./connect-select-options/gun-specification-options.reducer";
import { outsideDiameterOptions } from "./connect-select-options/outside-diameter-options.reducer";
import { phasingNeededOptions } from "./connect-select-options/phasing-needed-options.reducer";
import { gunOuterDiameterOptions } from "./connect-select-options/gun-outer-diameter-options.reducer";
import { explosiveWeightOptions } from "./connect-select-options/explosive-weight-options.reducer";
import { explosiveTypeOptions } from "./connect-select-options/explosive-type-options.reducer";
import { chargeTypeOptions } from "./connect-select-options/charge-type-options.reducer";

export const connectSelectOptions = combineReducers<any>({
  casingSize: casingSizeOptions,
  casingWeight: casingWeightOptions,
  maxTemperatureAtOneHour: maxTemperatureAtOneHourOptions,
  gunSpecification: gunSpecificationOptions,
  outsideDiameter: outsideDiameterOptions,
  phasingNeeded: phasingNeededOptions,
  gunOuterDiameter: gunOuterDiameterOptions,
  explosiveWeight: explosiveWeightOptions,
  explosiveType: explosiveTypeOptions,
  chargeType: chargeTypeOptions
});
