import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { IAccessTokenCredentials } from "../interfaces/access-token-credentials.interface";

import Debug from "debug";
import { APP_DEBUG_SCOPE } from "../constants/app-debug-scope.constants";
import { Subject } from "rxjs";
import { environment } from "../../environments/environment";

const debug = Debug(`${APP_DEBUG_SCOPE}:CredentialsService`);

@Injectable({
  providedIn: "root"
})
export class CredentialsService {
  private storageKey: string = "credentials";

  private cachedCredentials: IAccessTokenCredentials | null = null;

  private refreshTokenTimeoutId: number | null = null;

  private cachedCredentials$: Subject<IAccessTokenCredentials | null> = new Subject();

  private refreshTokenMinTimeout: number = 30; // in seconds
  private refreshTokenBeforeExpiration: number = 60; // in seconds

  constructor(private storage: Storage) {
    this.cachedCredentials$.subscribe(
      (credentials: IAccessTokenCredentials | null) => {
        this.cachedCredentials = credentials;

        if (this.refreshTokenTimeoutId) {
          clearTimeout(this.refreshTokenTimeoutId);
          this.refreshTokenTimeoutId = null;
        }

        if (credentials) {
          const refreshTokenTimeoutDuration: number = this.getRefreshTokenTimeoutDuration(
            credentials
          );

          debug(
            `credentials changed. They will be expired in ${this.getExpiresInFromCurrentTime(
              credentials
            )} seconds. I will try to refresh then after ${refreshTokenTimeoutDuration} seconds`
          );

          this.refreshTokenTimeoutId = setTimeout(() => {
            this.refreshCredentials(credentials)
              .then((newCredentials: IAccessTokenCredentials) =>
                this.setCredentials(newCredentials)
              )
              .catch(err => {
                debug(
                  `refresh token after ${refreshTokenTimeoutDuration} seconds error, clear credentials`,
                  err
                );
                return this.removeCredentials();
              });
          }, refreshTokenTimeoutDuration * 1000);
        }
      }
    );
  }

  getCredentials(): Promise<IAccessTokenCredentials | null> {
    return this.storage
      .get(this.storageKey)
      .then((credentials: IAccessTokenCredentials | null) => {
        if (!credentials) {
          return null;
        }

        if (this.needToRefreshCredentials(credentials)) {
          return this.refreshCredentials(
            credentials
          ).then((newCredentials: IAccessTokenCredentials) =>
            this.setCredentials(newCredentials)
          );
        } else {
          this.cachedCredentials$.next(credentials);
          return credentials;
        }
      })
      .catch(err => {
        debug(`get token error, clear credentials`, err);
        return this.storage.remove(this.storageKey).then(() => null);
      });
  }

  setCredentials(
    credentials: IAccessTokenCredentials
  ): Promise<IAccessTokenCredentials> {
    credentials = {
      ...credentials,
      expires_in: credentials.expires_in,
      timestamp: Math.floor(Date.now() / 1000)
    };

    return this.storage.set(this.storageKey, credentials).then(() => {
      this.cachedCredentials$.next(credentials);

      return credentials;
    });
  }

  removeCredentials(): Promise<void> {
    return this.storage.remove(this.storageKey).then(() => {
      this.cachedCredentials$.next(null);
    });
  }

  getCachedCredentials(): any {
    return this.cachedCredentials;
  }

  getAuthorizationHeaders(): any {
    if (this.cachedCredentials) {
      return {
        Authorization: this.cachedCredentials.access_token
      };
    }

    return {};
  }

  private needToRefreshCredentials(
    credentials: IAccessTokenCredentials
  ): boolean {
    const expiresAt = credentials.timestamp + credentials.expires_in;
    const currentTimestamp = Math.floor(Date.now() / 1000);

    if (currentTimestamp > expiresAt - this.refreshTokenBeforeExpiration) {
      return true;
    }
    return false;
  }

  private getRefreshTokenTimeoutDuration(
    credentials: IAccessTokenCredentials
  ): number {
    const expiresIn: number = this.getExpiresInFromCurrentTime(credentials);

    const refreshTokenTimeoutDuration: number =
      expiresIn - this.refreshTokenBeforeExpiration;
    if (refreshTokenTimeoutDuration < this.refreshTokenMinTimeout) {
      return this.refreshTokenMinTimeout;
    }
    return refreshTokenTimeoutDuration;
  }

  private getExpiresInFromCurrentTime(
    credentials: IAccessTokenCredentials
  ): number {
    const expiresAt = credentials.timestamp + credentials.expires_in;
    const currentTimestamp = Math.floor(Date.now() / 1000);

    return expiresAt - currentTimestamp;
  }

  private refreshCredentials(
    credentials: IAccessTokenCredentials
  ): Promise<IAccessTokenCredentials> {
    return fetch(`${environment.apiBaseUrl}/refresh-token`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        refresh_token: credentials.refresh_token
      })
    }).then(res => {
      return res.json().then(responseBody => {
        if (res.status !== 200) {
          debug(`refresh credentials error`, responseBody);
          throw responseBody;
        }
        debug(`refresh credentials ok`);
        return responseBody;
      });
    });
  }
}
