import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";

import Debug from "debug";
import { APP_DEBUG_SCOPE } from "../constants/app-debug-scope.constants";

const debug = Debug(`${APP_DEBUG_SCOPE}:authenticatedGuard`);

@Injectable({
  providedIn: "root"
})
export class AuthenticatedGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isAuthenticated()) {
      return true;
    }

    debug(`locked route: ${state.url}, redirect to /login`);
    return this.router.createUrlTree([
      "/login",
      {
        callbackUrl: state.url
      }
    ]);
  }
}
