import { Action, handleActions } from "redux-actions";
import {
  GET_ATTR_VALUES_ERROR,
  GET_ATTR_VALUES_REQUEST,
  GET_ATTR_VALUES_SUCCESS
} from "../../constants/attr-values.constants";
import { mergeWith as _mergeWith, isArray as _isArray } from "lodash";

const attributeName: string = "outsideDiameter";

const getAttrValuesRequest = (state: any, { payload }: Action<any>) => {
  if (payload.attribute === attributeName) {
    return _mergeWith(
      {},
      state,
      {
        [payload.casingSize + "_" + payload.casingWeight]: {
          inProcess: true,
          hasErrors: false,
          items: []
        }
      },
      (objValue, srcValue) => {
        if (_isArray(objValue)) {
          return objValue;
        }
      }
    );
  }

  return state;
};

const getAttrValuesError = (state: any, { payload }: Action<any>) => {
  if (payload.attribute === attributeName) {
    return _mergeWith(
      {},
      state,
      {
        [payload.casingSize + "_" + payload.casingWeight]: {
          inProcess: false,
          hasErrors: true,
          items: []
        }
      },
      (objValue, srcValue) => {
        if (_isArray(objValue)) {
          return objValue;
        }
      }
    );
  }

  return state;
};

const getAttrValuesSuccess = (state: any, { payload }: Action<any>) => {
  if (payload.attribute === attributeName) {
    return {
      ...state,
      [payload.casingSize + "_" + payload.casingWeight]: {
        inProcess: false,
        hasErrors: false,
        items: payload.result
      }
    };
  }

  return state;
};

const loadState = () => ({});

export const outsideDiameterOptions = handleActions(
  {
    [GET_ATTR_VALUES_REQUEST]: getAttrValuesRequest,
    [GET_ATTR_VALUES_ERROR]: getAttrValuesError,
    [GET_ATTR_VALUES_SUCCESS]: getAttrValuesSuccess
  },
  loadState()
);
