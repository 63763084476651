import { Action, handleActions } from "redux-actions";
import { GET_ALL_CONTENT_SUCCESS } from "../constants/all-content.constants";
import { keyBy as _keyBy } from "lodash";

const getAllContentSuccess = (state: any, { payload }: Action<any>) => {
  const itemsArr: any = payload.menus;
  console.log("Testing the getAllContentSuccess reducer");
  console.log(payload.menus);
  return _keyBy(itemsArr, "name");
};

const loadState = () => ({});

export const navMenus = handleActions(
  {
    [GET_ALL_CONTENT_SUCCESS]: getAllContentSuccess
  },
  loadState()
);
