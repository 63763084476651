import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { wizardConfig } from "../config/wizard-config";
import { toPairs as _toPairs } from "lodash";
import Debug from "debug";
import { APP_DEBUG_SCOPE } from "../constants/app-debug-scope.constants";
import { WizardStep } from "../enums/wizard-step.enum";
import { WizardService } from "../services/wizard.service";

const debug = Debug(`${APP_DEBUG_SCOPE}:wizardGuard`);

@Injectable({
  providedIn: "root"
})
export class WizardGuard implements CanActivate {
  constructor(private router: Router, private wizardService: WizardService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const nextWizardStepConfig = _toPairs(wizardConfig.steps).find(
      pairs => pairs[1].route === state.url
    );

    if (!nextWizardStepConfig) {
      const firstStepRoute = this.wizardService.getStepRoute(
        wizardConfig.firstStep
      );

      debug(
        `locked route: ${state.url}, wizard config not found for url ${state.url}, redirect to ${firstStepRoute}`
      );
      return this.router.parseUrl(firstStepRoute);
    }

    const nextWizardStep: WizardStep = nextWizardStepConfig[0] as WizardStep;

    const wizardHistory: WizardStep[] = this.wizardService.getCachedWizardHistory();

    if (
      nextWizardStep === wizardConfig.firstStep ||
      wizardHistory.includes(nextWizardStep)
    ) {
      return true;
    }

    if (
      wizardHistory.some(
        item => this.wizardService.getNextStep(item) === nextWizardStep
      )
    ) {
      return true;
    }

    const currentStep = this.wizardService.getCurrentStep();
    const currentStepRoute = this.wizardService.getStepRoute(currentStep);

    debug(
      `locked route: ${state.url}, please complete previous steps first, redirect to ${currentStepRoute}`
    );
    return this.router.parseUrl(currentStepRoute);
  }
}
