import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthenticatedGuard } from "./guards/authenticated.guard";
import { WizardGuard } from "./guards/wizard.guard";
import { NotAuthenticatedGuard } from "./guards/not-authenticated.guard";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/home/home.module").then(m => m.HomePageModule)
  },
  {
    path: "login",
    canActivate: [NotAuthenticatedGuard],
    data: {
      showLoginModal: true,
      modalViewMode: "login"
    },
    loadChildren: () =>
      import("./pages/home/home.module").then(m => m.HomePageModule)
  },
  {
    path: "registration",
    canActivate: [NotAuthenticatedGuard],
    data: {
      showLoginModal: true,
      modalViewMode: "registration"
    },
    loadChildren: () =>
      import("./pages/home/home.module").then(m => m.HomePageModule)
  },
  {
    path: "wizard-registration",
    canActivate: [WizardGuard],
    loadChildren: () =>
      import("./pages/wizard-registration/wizard-registration.module").then(
        m => m.WizardRegistrationPageModule
      )
  },
  {
    path: "wizard-selector",
    canActivate: [WizardGuard],
    loadChildren: () =>
      import("./pages/wizard-selector/wizard-selector.module").then(
        m => m.WizardSelectorPageModule
      )
  },
  {
    path: "wizard-operations",
    canActivate: [WizardGuard],
    loadChildren: () =>
      import("./pages/wizard-operations/wizard-operations.module").then(
        m => m.WizardOperationsPageModule
      )
  },
  {
    path: "wizard-well-profile",
    canActivate: [WizardGuard],
    loadChildren: () =>
      import("./pages/wizard-well-profile/wizard-well-profile.module").then(
        m => m.WizardWellProfilePageModule
      )
  },
  {
    path: "wizard-gun-profile",
    canActivate: [WizardGuard],
    loadChildren: () =>
      import("./pages/wizard-gun-profile/wizard-gun-profile.module").then(
        m => m.WizardGunProfilePageModule
      )
  },
  {
    path: "wizard-charge-profile",
    canActivate: [WizardGuard],
    loadChildren: () =>
      import("./pages/wizard-charge-profile/wizard-charge-profile.module").then(
        m => m.WizardChargeProfilePageModule
      )
  },
  {
    path: "wizard-guns",
    canActivate: [WizardGuard],
    loadChildren: () =>
      import("./pages/wizard-guns/wizard-guns.module").then(
        m => m.WizardGunsPageModule
      )
  },
  {
    path: "wizard-charges",
    canActivate: [WizardGuard],
    loadChildren: () =>
      import("./pages/wizard-charges/wizard-charges.module").then(
        m => m.WizardChargesPageModule
      )
  },
  {
    path: "wizard-tools-and-hardware",
    canActivate: [WizardGuard],
    loadChildren: () =>
      import(
        "./pages/wizard-tools-and-hardware/wizard-tools-and-hardware.module"
      ).then(m => m.WizardToolsAndHardwarePageModule)
  },
  {
    path: "direct-tools-and-hardware",
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import(
        "./pages/direct-tools-and-hardware/direct-tools-and-hardware.module"
      ).then(m => m.DirectToolsAndHardwarePageModule)
  },
  {
    path: "wizard-bill-of-materials",
    canActivate: [WizardGuard, AuthenticatedGuard],
    loadChildren: () =>
      import(
        "./pages/wizard-bill-of-materials/wizard-bill-of-materials.module"
      ).then(m => m.WizardBillOfMaterialsPageModule)
  },
  {
    path: "wizard-request-gem",
    canActivate: [WizardGuard],
    loadChildren: () =>
      import("./pages/wizard-request-gem/wizard-request-gem.module").then(
        m => m.WizardRequestGemPageModule
      )
  },
  {
    path: "orders-history",
    canActivate: [AuthenticatedGuard],
    loadChildren: () =>
      import("./pages/orders-history/orders-history.module").then(
        m => m.OrdersHistoryPageModule
      )
  },
  {
    path: "privacy",
    loadChildren: () =>
      import("./pages/privacy/privacy.module").then(m => m.PrivacyPageModule)
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./pages/privacy/privacy.module").then(m => m.PrivacyPageModule)
  },
  {
    path: "terms",
    loadChildren: () =>
      import("./pages/terms/terms.module").then(m => m.TermsPageModule)
  },
  {
    path: "terms-and-conditions",
    loadChildren: () =>
      import("./pages/terms/terms.module").then(m => m.TermsPageModule)
  },
  {
    path: "contact",
    loadChildren: () =>
      import("./pages/contact/contact.module").then(m => m.ContactPageModule)
  },
  {
    path: "wizard-tcp",
    canActivate: [WizardGuard],
    loadChildren: () =>
      import("./pages/wizard-tcp/wizard-tcp.module").then(
        m => m.WizardTcpPageModule
      )
  },
  {
    path: "wizard-well-profile-2",
    canActivate: [WizardGuard],
    loadChildren: () =>
      import("./pages/wizard-well-profile2/wizard-well-profile2.module").then(
        m => m.WizardWellProfile2PageModule
      )
  },
  {
    path: "wizard-gun-type",
    canActivate: [WizardGuard],
    loadChildren: () =>
      import("./pages/wizard-gun-type/wizard-gun-type.module").then(
        m => m.WizardGunTypePageModule
      )
  }
];

routes.push({
  path: "**",
  loadChildren: () =>
    import("./pages/universal/universal.module").then(
      m => m.UniversalPageModule
    )
});

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
