// get wizard data (begin)
export const GET_WIZARD_DATA_SUCCESS = "GET_WIZARD_DATA_SUCCESS";
// get wizard data (end)

// get wizard history (begin)
export const GET_WIZARD_HISTORY_SUCCESS = "GET_WIZARD_HISTORY_SUCCESS";
// get wizard history (begin)

// set wizard data (begin)
export const SET_WIZARD_DATA_SUCCESS = "SET_WIZARD_DATA_SUCCESS";
// set wizard data (end)

// send order (begin)
export const SEND_ORDER_SUCCESS = "SEND_ORDER_SUCCESS";
// send order (end)

// send order (begin)
export const REPEAT_ORDER_REQUEST = "REPEAT_ORDER_REQUEST";
export const REPEAT_ORDER_SUCCESS = "REPEAT_ORDER_SUCCESS";
export const REPEAT_ORDER_ERROR = "REPEAT_ORDER_ERROR";
// send order (end)

// get orders history (begin)
export const GET_ORDERS_HISTORY_REQUEST = "GET_ORDERS_HISTORY_REQUEST";
export const GET_ORDERS_HISTORY_SUCCESS = "GET_ORDERS_HISTORY_SUCCESS";
export const GET_ORDERS_HISTORY_ERROR = "GET_ORDERS_HISTORY_ERROR";
// get orders history (end)

// delete order (begin)
export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_ERROR = "DELETE_ORDER_ERROR";
// delete order (end)

// push to wizard history (begin)
export const PUSH_TO_WIZARD_HISTORY_SUCCESS = "PUSH_TO_WIZARD_HISTORY_SUCCESS";
// push to wizard history (end)
