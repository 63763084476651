import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import {
  NGX_REDUX_CONFIG_TOKEN,
  NgxReduxModule,
  StoreConfiguratorService
} from "@bubbleup/ngx-redux";
import { AuthService } from "./services/auth.service";
import { appInitializer } from "./utils/app-initializer";
import { IonicStorageModule } from "@ionic/storage";
import { WizardService } from "./services/wizard.service";
import { rootReducer } from "./redux/reducers";
import { NgxCommonModule } from "@bubbleup/ngx-common";
import { CredentialsService } from "./services/credentials.service";
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";

// @todo. find better way to set recaptcha site key
// @ts-ignore
window.BUBBLEUP_RECAPTCHA_SITE_KEY = "6Ldst-IUAAAAAICENIt_uUUtiXLuQOZ53xiMkQwa";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NgxReduxModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    IonicStorageModule.forRoot(),
    NgxCommonModule.forRoot({
      defaultPageMeta: {
        title: "Perforating Configuration",
        description: "",
        ogAudio: "",
        ogImage: "",
        ogVideo: ""
      }
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [
        StoreConfiguratorService,
        AuthService,
        WizardService,
        CredentialsService
      ],
      multi: true
    },
    {
      provide: NGX_REDUX_CONFIG_TOKEN,
      useValue: {
        rootReducer
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
