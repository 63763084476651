// init current user (begin)
import { createAction } from "redux-actions";

export const INIT_CURRENT_USER_SUCCESS = "INIT_CURRENT_USER_SUCCESS";
// init current user (end)

// sign in (begin)
export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
// sign in (end)

// sign up (begin)
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";
// sign up (end)

// logout (begin)
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
// logout (end)
