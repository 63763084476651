import { Action, handleActions } from "redux-actions";
import {
  DELETE_ORDER_SUCCESS,
  GET_ORDERS_HISTORY_SUCCESS,
  REPEAT_ORDER_SUCCESS,
  SEND_ORDER_SUCCESS
} from "../constants/wizard.constants";
import { LOGOUT_SUCCESS } from "../constants/auth.constants";

const getOrdersHistorySuccess = (state: any, { payload }: Action<any>) => {
  return payload;
};

const sendOrderSuccess = (state: any, { payload }: Action<any>) => {
  return [payload.order, ...state];
};
const repeatOrderSuccess = sendOrderSuccess;

const deleteOrderSuccess = (state: any, { payload }: Action<any>) => {
  return state.filter(item => item.instance_id !== payload);
};

const logoutSuccess = (state: any, { payload }: Action<any>) => {
  return loadState();
};

const loadState = () => [];

export const ordersHistory = handleActions(
  {
    [SEND_ORDER_SUCCESS]: sendOrderSuccess,
    [REPEAT_ORDER_SUCCESS]: repeatOrderSuccess,
    [GET_ORDERS_HISTORY_SUCCESS]: getOrdersHistorySuccess,
    [DELETE_ORDER_SUCCESS]: deleteOrderSuccess,
    [LOGOUT_SUCCESS]: logoutSuccess
  },
  loadState()
);
