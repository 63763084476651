import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { WizardStep } from "../enums/wizard-step.enum";
import { NgRedux } from "@angular-redux/store";
import {
  getWizardDataSuccess,
  getWizardHistorySuccess,
  pushToWizardHistorySuccess,
  sendOrderSuccess,
  setWizardDataSuccess
} from "../redux/actions/wizard.actions";
import { isFunction as _isFunction } from "lodash";
import { Router } from "@angular/router";
import { wizardConfig } from "../config/wizard-config";
import { ConnectService } from "./connect.service";

@Injectable({
  providedIn: "root"
})
export class WizardService {
  private wizardDataStorageKey: string = "wizardData";
  private wizardHistoryStorageKey: string = "wizardHistory";

  constructor(
    private storage: Storage,
    private redux: NgRedux<any>,
    private connectService: ConnectService
  ) {}

  getWizardData(): Promise<any> {
    return this.storage.get(this.wizardDataStorageKey).then(res => {
      this.redux.dispatch(getWizardDataSuccess(res || {}));
    });
  }

  getWizardHistory(): Promise<any> {
    return this.storage.get(this.wizardHistoryStorageKey).then(res => {
      this.redux.dispatch(getWizardHistorySuccess(res || []));
    });
  }

  getCachedWizardData(): any {
    return this.redux.getState().wizardData;
  }

  getCachedWizardStepData(step: WizardStep): any {
    return this.redux.getState().wizardData[step];
  }

  getCachedWizardHistory(): WizardStep[] {
    return this.redux.getState().wizardHistory;
  }

  setWizardStepData(step: WizardStep, data: any): Promise<any> {
    const wizardData = this.redux.getState().wizardData;

    const newWizardData = {
      ...wizardData,
      [step]: data
    };

    return this.storage
      .set(this.wizardDataStorageKey, newWizardData)
      .then(() => {
        this.redux.dispatch(setWizardDataSuccess(newWizardData));
      });
  }

  resetWizardStepData(): Promise<any> {
    const wizardData = this.redux.getState().wizardData;

    const newWizardData = {};

    this.resetWizardHistory();

    return this.storage
      .set(this.wizardDataStorageKey, newWizardData)
      .then(() => {
        this.redux.dispatch(setWizardDataSuccess(newWizardData));
      });
  }

  sendOrder(toolstringId): Promise<void> {
    return this.connectService
      .sendOrder(toolstringId)
      .then(order => {
        return Promise.all([
          this.storage.remove(this.wizardDataStorageKey),
          this.storage.remove(this.wizardHistoryStorageKey)
        ]).then(() => {
          return order;
        });
      })
      .then(order => {
        this.redux.dispatch(
          sendOrderSuccess({
            order
          })
        );
      })
      .catch(err => {
        console.log("create-order error", err);
      });
  }

  navigateToCurrentStep(router: Router): Promise<boolean> {
    return this.navigateToStep(router, this.getCurrentStep());
  }

  // we can't inject Router in constructor because this service is called in initilizer
  navigateToNextStep(
    router: Router,
    currentStep: WizardStep
  ): Promise<boolean> {
    const nextStep = this.getNextStep(currentStep);
    console.log("tracking step nav");
    if (!nextStep) {
      throw Error(`There is no next step for current step: "${currentStep}"`);
    }

    return this.pushToWizardHistory(currentStep).then(() =>
      this.navigateToStep(router, nextStep)
    );
  }

  // we can't inject Router in constructor because this service is called in initilizer
  navigateToPrevStep(
    router: Router,
    currentStep: WizardStep
  ): Promise<boolean> {
    const prevStep = this.getPrevStep(currentStep);

    if (!prevStep) {
      throw Error(
        `There is no previous step for current step: "${currentStep}"`
      );
    }

    return this.navigateToStep(router, prevStep);
  }

  // we can't inject Router in constructor because this service is called in initilizer
  navigateToStep(router: Router, step: WizardStep): Promise<boolean> {
    return router.navigate([wizardConfig.steps[step].route]);
  }

  getStepRoute(step: WizardStep): string {
    return wizardConfig.steps[step].route;
  }

  getCurrentStep(): WizardStep {
    const wizardHistory: WizardStep[] = this.getCachedWizardHistory();

    if (!wizardHistory.length) {
      return wizardConfig.firstStep;
    } else {
      return this.getNextStep(wizardHistory[wizardHistory.length - 1]);
    }
  }

  getNextStep(currentStep: WizardStep): WizardStep {
    const currentStepConfig = wizardConfig.steps[currentStep];
    if (!currentStepConfig) {
      throw Error(`unknown step: ${currentStep}`);
    }

    const wizardData = this.getCachedWizardData();
    if (!wizardData[currentStep]) {
      throw Error(
        `You are trying to get next step route, but you did not set data to current route "${currentStep}"`
      );
    }

    let nextStep = currentStepConfig.nextStep;

    if (!nextStep) {
      return null;
    }

    if (_isFunction(nextStep)) {
      nextStep = nextStep(wizardData[currentStep], wizardData);
    }

    return nextStep;
  }

  getPrevStep(currentStep: WizardStep): WizardStep | null {
    const history = this.getCachedWizardHistory();

    if (!history.length) {
      return null;
    }

    const currentStepIndex: number = history.indexOf(currentStep);

    let lastStep: WizardStep;
    if (currentStepIndex === -1) {
      lastStep = history[history.length - 1];
    } else if (currentStepIndex === 0) {
      return null;
    } else {
      lastStep = history[currentStepIndex - 1];
    }

    return lastStep;
  }

  private resetWizardHistory() {
    this.redux.dispatch(pushToWizardHistorySuccess(wizardConfig.firstStep));

    const wizardHistory = this.getCachedWizardHistory();

    return this.storage.set(this.wizardHistoryStorageKey, wizardHistory);
  }

  private pushToWizardHistory(step: WizardStep) {
    this.redux.dispatch(pushToWizardHistorySuccess(step));

    const wizardHistory = this.getCachedWizardHistory();

    return this.storage.set(this.wizardHistoryStorageKey, wizardHistory);
  }
}
