import { Injectable } from "@angular/core";
import { NgRedux } from "@angular-redux/store";
import {
  allContentAuthSampleData,
  allContentUnauthSampleData
} from "../data/all-content";
import {
  getAllContentError,
  getAllContentRequest,
  getAllContentSuccess
} from "../redux/actions/all-content.actions";
import {
  getAttrValuesError,
  getAttrValuesRequest,
  getAttrValuesSuccess
} from "../redux/actions/attr-values.actions";
import {
  deleteOrderError,
  deleteOrderRequest,
  deleteOrderSuccess,
  getOrdersHistoryError,
  getOrdersHistoryRequest,
  getOrdersHistorySuccess,
  repeatOrderError,
  repeatOrderRequest,
  repeatOrderSuccess
} from "../redux/actions/wizard.actions";
import { CredentialsService } from "./credentials.service";
import { HttpMethod } from "../enums/http-method.enum";
import Debug from "debug";
import { APP_DEBUG_SCOPE } from "../constants/app-debug-scope.constants";
import { environment } from "../../environments/environment";

const debug = Debug(`${APP_DEBUG_SCOPE}:ConnectService`);

@Injectable({
  providedIn: "root"
})
export class ConnectService {
  constructor(
    private redux: NgRedux<any>,
    private credentialsService: CredentialsService
  ) {}

  callApi<T>(
    path: string,
    method: HttpMethod,
    params: any = null,
    queryParams: any = null
  ): Promise<T> {
    const authHeaders = this.credentialsService.getAuthorizationHeaders();

    console.log(path, authHeaders);
    authHeaders.Authorization = authHeaders.Authorization;

    const requestInit: RequestInit = {
      method,
      headers: {
        ...authHeaders
      }
    };

    if (params) {
      requestInit.headers["Content-Type"] = "application/json";
      requestInit.body = JSON.stringify(params);
    }

    let queryParamsStr: string = "";
    if (queryParams) {
      queryParamsStr = "?" + new URLSearchParams(queryParams).toString();
    }

    return fetch(
      `${environment.apiBaseUrl}/${path}${queryParamsStr}`,
      requestInit
    ).then(res => {
      return res.json().then(responseBody => {
        if (res.status === 401) {
          debug(`callApi ${method} ${path} returns 401, clear credentials`);
          return this.credentialsService.removeCredentials().finally(() => {
            throw responseBody;
          });
        } else if (res.status !== 200) {
          throw responseBody;
        }
        return responseBody;
      });
    });
  }

  unauthCallApi<T>(
    path: string,
    method: HttpMethod,
    params: any = null,
    queryParams: any = null
  ): Promise<T> {
    const requestInit: RequestInit = {
      method,
      headers: {}
    };

    if (params) {
      requestInit.headers["Content-Type"] = "application/json";
      requestInit.body = JSON.stringify(params);
    }

    let queryParamsStr: string = "";
    if (queryParams) {
      queryParamsStr = "?" + new URLSearchParams(queryParams).toString();
    }

    return fetch(
      `${environment.apiBaseUrl}/${path}${queryParamsStr}`,
      requestInit
    ).then(res => {
      return res.json().then(responseBody => {
        if (res.status !== 200) {
          throw responseBody;
        }
        return responseBody;
      });
    });
  }

  addToolstringItem(data: any): Promise<any> {
    return this.callApi("add-toolstring-item", HttpMethod.POST, data);
  }

  createToolstring(wizardData: any): Promise<any> {
    return this.callApi("create-toolstring", HttpMethod.POST, wizardData);
  }

  getToolstringItems(toolstringInstanceId: string): Promise<any> {
    return this.callApi("toolstring-items", HttpMethod.GET, null, {
      toolstringId: toolstringInstanceId
    });
  }

  getAllContent(): Promise<any> {
    this.redux.dispatch(getAllContentRequest());

    return this.callApi<any>("all-content", HttpMethod.GET)
      .then(res => {
        this.redux.dispatch(getAllContentSuccess(res));
      })
      .catch(err => {
        this.redux.dispatch(getAllContentError(err));
      });

    /* const isAuthenticated = true;

    return new Promise((resolve, reject) => {
      const allContent: any = isAuthenticated
        ? allContentAuthSampleData
        : allContentUnauthSampleData;

      setTimeout(() => {
        this.redux.dispatch(getAllContentSuccess(allContent));
        resolve(allContent);
      }, 2000);
    });*/
  }

  getAttrValues(
    attribute: string,
    casingSize: string = "0",
    casingWeight: string = "0",
    accessoryTier1: string = ""
  ): Promise<any> {
    this.redux.dispatch(
      getAttrValuesRequest({
        attribute,
        casingSize,
        casingWeight,
        accessoryTier1
      })
    );

    return this.callApi<any>("attr-values", HttpMethod.GET, null, {
      attribute,
      casingSize,
      casingWeight,
      accessoryTier1
    })
      .then(res => {
        res.unshift({
          id: "0",
          metricValue: "Select",
          standardValue: "Select"
        });

        return res;
      })
      .then(res => {
        this.redux.dispatch(
          getAttrValuesSuccess({
            attribute,
            casingSize,
            casingWeight,
            accessoryTier1,
            result: res
          })
        );

        return res;
      })
      .catch(err => {
        this.redux.dispatch(
          getAttrValuesError({
            attribute,
            casingSize,
            casingWeight,
            accessoryTier1,
            error: err
          })
        );

        throw err;
      });
  }

  getGunTypes(wizardData: any): Promise<any> {
    return this.callApi("gun-categories", HttpMethod.POST, wizardData);
  }

  searchProducts(wizardData: any): Promise<any> {
    return this.callApi("search-products", HttpMethod.POST, wizardData);
  }

  sendOrder(toolstringId): Promise<any> {
    return this.callApi<any>("create-order", HttpMethod.POST, {
      toolstringId,
      orderStatus: "submitted"
    });
  }

  getCompatibleProducts(
    productId: string,
    type: "guns" | "charges" | "accessories"
  ): Promise<any> {
    return this.callApi<any>("compatible-products", HttpMethod.GET, null, {
      instanceId: productId,
      type
    });
  }

  getTotalWorkingPressure(
    fluidWeight: string,
    kickOffPoint: string,
    wellheadPsi: string
  ): Promise<any> {
    return this.callApi<any>("total-working-pressure", HttpMethod.GET, null, {
      fluidWeight,
      kickOffPoint,
      wellheadPsi
    });
  }

  getOrdersHistory(): Promise<any> {
    this.redux.dispatch(getOrdersHistoryRequest());
    return this.callApi("user-orders", HttpMethod.GET)
      .then(res => {
        this.redux.dispatch(getOrdersHistorySuccess(res));
        return res;
      })
      .catch(err => {
        this.redux.dispatch(getOrdersHistoryError(err));
        throw err;
      });
  }

  repeatOrder(orderId): Promise<any> {
    this.redux.dispatch(repeatOrderRequest());

    return this.callApi<any>("repeat-order", HttpMethod.POST, {
      orderId
    })
      .then(order => {
        this.redux.dispatch(
          repeatOrderSuccess({
            order
          })
        );

        return order;
      })
      .catch(err => {
        this.redux.dispatch(repeatOrderError(err));

        throw err;
      });
  }

  deleteOrder(id: number): Promise<void> {
    this.redux.dispatch(deleteOrderRequest(id));

    return this.callApi<any>("order", HttpMethod.DELETE, null, {
      orderId: id
    })
      .then(order => {
        this.redux.dispatch(deleteOrderSuccess(id));
      })
      .catch(err => {
        this.redux.dispatch(deleteOrderError(err));

        throw err;
      });
  }

  submitForm(formPath: string, formData: any): Promise<void> {
    return this.callApi<any>("submit-form", HttpMethod.POST, {
      formPath,
      formData
    });
  }
}
