import { Action, handleActions } from "redux-actions";
import {
  GET_ATTR_VALUES_ERROR,
  GET_ATTR_VALUES_REQUEST,
  GET_ATTR_VALUES_SUCCESS
} from "../../constants/attr-values.constants";

const attributeName: string = "explosiveWeight";

const getAttrValuesRequest = (state: any, { payload }: Action<any>) => {
  if (payload.attribute === attributeName) {
    return {
      ...state,
      inProcess: true,
      hasErrors: false
    };
  }

  return state;
};

const getAttrValuesError = (state: any, { payload }: Action<any>) => {
  if (payload.attribute === attributeName) {
    return {
      ...state,
      inProcess: false,
      hasErrors: true
    };
  }

  return state;
};

const getAttrValuesSuccess = (state: any, { payload }: Action<any>) => {
  if (payload.attribute === attributeName) {
    return {
      items: payload.result,
      inProcess: false,
      hasErrors: false
    };
  }

  return state;
};

const loadState = () => ({
  inProcess: true,
  hasErrors: false,
  items: []
});

export const explosiveWeightOptions = handleActions(
  {
    [GET_ATTR_VALUES_REQUEST]: getAttrValuesRequest,
    [GET_ATTR_VALUES_ERROR]: getAttrValuesError,
    [GET_ATTR_VALUES_SUCCESS]: getAttrValuesSuccess
  },
  loadState()
);
